export namespace Location {
    export enum E {
        TEACHER_S_PLACE = 'TEACHER_S_PLACE',
        STUDENT_S_PLACE = 'STUDENT_S_PLACE',
        REMOTE = 'REMOTE',
        SACRE_COEUR_ABLON_SUR_SEINE = 'SACRE_COEUR_ABLON_SUR_SEINE',
        IND_SANNOIS = 'IND_SANNOIS',
        LES_COQUELICOTS_TIGERY = 'LES_COQUELICOTS_TIGERY',
        ECOLE_DES_ORMES_TIGERY = 'ECOLE_DES_ORMES_TIGERY',
        ESPACE_MOZAIK_CHAVILLE = 'ESPACE_MOZAIK_CHAVILLE',
        MAISON_ENFANCE_ET_JEUNESSE_CHAVILLE = 'MAISON_ENFANCE_ET_JEUNESSE_CHAVILLE',
        MAISON_DE_QUARTIER_PASTEUR_SANNOIS = 'MAISON_DE_QUARTIER_PASTEUR_SANNOIS',
        ECOLE_EMILE_ROUX_SANNOIS = 'ECOLE_EMILE_ROUX_SANNOIS',
        IBN_BADIS = 'IBN_BADIS',
        SAINTE_ELISABETH = 'SAINTE_ELISABETH',
        SAINT_LOUIS_MONTCALM = 'SAINT_LOUIS_MONTCALM',
        SALLE_PAULETTE_ARAGON_LA_FRETTE_SUR_SEINE = 'SALLE_PAULETTE_ARAGON_LA_FRETTE_SUR_SEINE',
        RIS_ORANGIS_ECOLE_ELEMENTAIRE = 'RIS_ORANGIS_ECOLE_ELEMENTAIRE',
        RIS_ORANGIS_DERRIDA = 'RIS_ORANGIS_DERRIDA',
        RIS_ORANGIS_GUERTON = 'RIS_ORANGIS_GUERTON',
        RIS_ORANGIS_MOULIN_A_VENT = 'RIS_ORANGIS_MOULIN_A_VENT',
        RIS_ORANGIS_FERME_DU_TEMPLE = 'RIS_ORANGIS_FERME_DU_TEMPLE',
        RIS_ORANGIS_SALLE = 'RIS_ORANGIS_SALLE',
        LE_1901_MAISON_DES_ASSOCIATIONS_CROSNE = 'LE_1901_MAISON_DES_ASSOCIATIONS_CROSNE',
        CEC_YERRES = "CEC_YERRES",
        ECOLE_SAINT_EXUPERY_YERRES = 'ECOLE_SAINT_EXUPERY_YERRES',
        AIME_CESAIRE = 'AIME_CESAIRE',
        LES_BERGERIES = 'LES_BERGERIES',
        ECOLE_LOUISE_MICHEL_VIGNEUX = 'ECOLE_LOUISE_MICHEL_VIGNEUX',
        SALLE_GASTON_VIAL_VIGNEUX = 'SALLE_GASTON_VIAL_VIGNEUX',
        REUSSITE_EDUCATIVE_VIGNEUX = 'REUSSITE_EDUCATIVE_VIGNEUX',
        SAINT_ROCH_BOUTIGNY_SUR_ESSONE = 'SAINT_ROCH_BOUTIGNY_SUR_ESSONE',
        BUISSONNIERE_BOUTIGNY_SUR_ESSONE = 'BUISSONNIERE_BOUTIGNY_SUR_ESSONE',
        MEET_IN_CLASS_OFFICE = 'MEET_IN_CLASS_OFFICE',
        SALLE_ROBERT_DUBOIS_BRUNOY = 'SALLE_ROBERT_DUBOIS_BRUNOY',
        ESPACE_LECLERC_BRUNOY = 'ESPACE_LECLERC_BRUNOY',
        SALLE_MUNICIPALE_SAINT_LEU_LA_FORET = 'SALLE_MUNICIPALE_SAINT_LEU_LA_FORET',
        ECOLE_PIERRE_LAROUSSE_BUCHELAY = 'ECOLE_PIERRE_LAROUSSE_BUCHELAY',
    }

    export function message(location: E, teachersPlaceMessage: string | null, studentsPlaceMessage: string | null): string | null {
        switch (location) {
            case E.TEACHER_S_PLACE:
                return teachersPlaceMessage;
            case E.STUDENT_S_PLACE:
                return studentsPlaceMessage;
            case E.REMOTE:
                return 'Cours en visio';
            case E.SACRE_COEUR_ABLON_SUR_SEINE:
                return 'École Collège du Sacré-Cœur';
            case E.IND_SANNOIS:
                return 'Notre Dame Sainte Famille';
            case E.LES_COQUELICOTS_TIGERY:
                return 'Centre de loisir Les coquelicots';
            case Location.E.ECOLE_DES_ORMES_TIGERY:
                return 'École Des Ormes';
            case E.ESPACE_MOZAIK_CHAVILLE:
                return 'Espace Mozaïk';
            case E.MAISON_ENFANCE_ET_JEUNESSE_CHAVILLE:
                return 'Maison de l\'Enfance et de la Jeunesse';
            case E.MAISON_DE_QUARTIER_PASTEUR_SANNOIS:
                return 'Maison de quartier Pasteur';
            case E.ECOLE_EMILE_ROUX_SANNOIS:
                return 'École Émile Roux';
            case E.IBN_BADIS:
                return 'Institut Ibn Badis';
            case E.SAINTE_ELISABETH:
                return 'École, Collège, Lycée Privés Sainte Elisabeth';
            case E.SAINT_LOUIS_MONTCALM:
                return 'Saint-Louis Montcalm';
            case E.SALLE_PAULETTE_ARAGON_LA_FRETTE_SUR_SEINE:
                return 'Salle Paulette Aragon';
            case E.RIS_ORANGIS_ECOLE_ELEMENTAIRE:
                return 'École élémentaire d’Orangis';
            case E.RIS_ORANGIS_DERRIDA:
                return 'École Derrida';
            case E.RIS_ORANGIS_GUERTON:
                return 'École Guerton';
            case E.RIS_ORANGIS_MOULIN_A_VENT:
                return 'École Moulin À Vent';
            case E.RIS_ORANGIS_FERME_DU_TEMPLE:
                return 'École Ferme du Temple';
            case E.RIS_ORANGIS_SALLE:
                return 'Salle Ris Orangis';
            case E.LE_1901_MAISON_DES_ASSOCIATIONS_CROSNE:
                return 'Le 1901, Maison des Associations';
            case E.CEC_YERRES:
                return 'CEC Yerres';
            case E.ECOLE_SAINT_EXUPERY_YERRES:
                return 'École Saint-Exupéry - Salle Polyvalente';
            case E.AIME_CESAIRE:
                return 'Centre social Aimé Césaire'
            case E.LES_BERGERIES:
                return 'Centre social intercommunal les Bergeries'
            case E.ECOLE_LOUISE_MICHEL_VIGNEUX:
                return 'École Louise Michel'
            case E.SALLE_GASTON_VIAL_VIGNEUX:
                return 'Salle Gaston Vial'
            case E.REUSSITE_EDUCATIVE_VIGNEUX:
                return 'Réussite Éducative'
            case E.SAINT_ROCH_BOUTIGNY_SUR_ESSONE:
                return 'Salle Saint-Roch'
            case E.BUISSONNIERE_BOUTIGNY_SUR_ESSONE:
                return 'Salle de la Buissonnière'
            case E.MEET_IN_CLASS_OFFICE:
                return 'Salle Meet in Class'
            case E.SALLE_ROBERT_DUBOIS_BRUNOY:
                return 'Salle Robert Dubois'
            case E.ESPACE_LECLERC_BRUNOY:
                return 'Espace Leclerc'
            case E.SALLE_MUNICIPALE_SAINT_LEU_LA_FORET:
                return 'Salle municipale de Saint-Leu-la-Forêt'
            case E.ECOLE_PIERRE_LAROUSSE_BUCHELAY:
                return 'École Primaire Pierre Larousse'
        }
    }
}

