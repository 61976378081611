document.addEventListener('DOMContentLoaded', () => {
    const forms = document.querySelectorAll('form.prevent-multiple-submit') as NodeListOf<HTMLFormElement>
    forms.forEach(form => {
        let submitted = false;
        form.addEventListener('submit', event => {
            if (submitted) {
                Logger.info('Prevent submit')
                event.preventDefault();
            }
            submitted = true;
        })
    })
})
