document.addEventListener('DOMContentLoaded', () => {
    const buttons = document.querySelectorAll('[data-copy]') as NodeListOf<HTMLButtonElement>
    buttons.forEach(button => {
        const textToCopy = button.dataset["copy"]!
        const innerHtmlBeforeCopy = button.innerHTML
        button.addEventListener('click', () => {
            if ('clipboard' in navigator) {
                navigator.clipboard.writeText(textToCopy).then(() => {
                    displayCopyConfirmed(button)
                }, () => {
                    fallbackCopy(textToCopy)
                    displayCopyConfirmed(button)
                })
            } else {
                fallbackCopy(textToCopy)
                displayCopyConfirmed(button)
            }
            setTimeout(() => {
                resetDisplay(button, innerHtmlBeforeCopy)
            }, 2000)
        })
    })
})

function fallbackCopy(textToCopy: string) {
    var tempInput = document.createElement('input')
    tempInput.style.position = 'absolute'
    tempInput.style.left = '-1000px'
    tempInput.style.top = '-1000px'
    tempInput.value = textToCopy
    document.body.appendChild(tempInput)
    tempInput.select()
    document.execCommand('copy')
    document.body.removeChild(tempInput)
}

function displayCopyConfirmed(button: HTMLButtonElement) {
    button.innerText = 'Copié !'
    button.classList.add('text-valid')
}

function resetDisplay(button: HTMLButtonElement, htmlToReset: string) {
    button.innerHTML = htmlToReset
    button.classList.remove('text-valid')
}