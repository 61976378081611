/*
Dropdowns are supposed to be structured like this:
<div data-dropdown>
    <button data-dropdown-button>
    <div data-dropdown-panel>
</div>
Elements can have deeper depths.
 */
document.addEventListener('DOMContentLoaded', () => {
  const dropdownPanels = document.querySelectorAll('[data-dropdown-panel]')
  document.addEventListener('click', event => {
    dropdownPanels.forEach(dropdownPanel => {
      if (!dropdownPanel.closest('[data-dropdown]').contains(event.target)) {
        dropdownPanel.style.display = 'none'
      }
    })
  })
  const buttons = document.querySelectorAll('[data-dropdown-button]')
  buttons.forEach(button => {
    button.addEventListener('click', event => {
      const panel = button.closest('[data-dropdown]').querySelector('[data-dropdown-panel]')
      panel.style.display = panel.style.display === 'none' ? '' : 'none'
    })
  })
})
