interface Window {
    toggleDisplayById: any;
    eraseInputValue: any;
}

window.addEventListener('pageshow', event => {
    document.querySelectorAll("[required]").forEach(
        requiredElement => {
            requiredElement.setAttribute("toggle-required", "");
            (requiredElement as HTMLInputElement).required = requiredElement.checkVisibility();
        }
    )
});

window.toggleDisplayById = function () {
    Array.from(arguments).forEach(
        (id) => {
            const element = document.getElementById(id)!;
            setVisibilityAndInputsRequired(element, element.style.display === 'none')
        })
}

document.addEventListener('DOMContentLoaded', event => {
    const radioToggles = Array.from(document.querySelectorAll('input[type=radio][data-radio-toggle-selector]') as NodeListOf<HTMLInputElement>);

    // a radio with data-radio-toggle-selector could be coupled (i.e. in the same
    // radio group) with an other radio without data-radio-toggle-selector. In such
    // case, we need to bind a change event on this second radio too.
    const otherRadiosInSameGroups = Array.from(radioToggles)
        .filter(radio => !!radio.name)
        .flatMap(radio => Array.from(document.querySelectorAll(`input[type=radio][name=${radio.name}]`) as NodeListOf<HTMLInputElement>))
        .filter(radio => !radio.dataset['radioToggleSelector']);

    [...radioToggles, ...otherRadiosInSameGroups].forEach(radio => {
        radio.addEventListener('change', (event) => {
            hideAllRadioToggleSelectors()
            displaySelectedRadioToggleSelector()
        })
    })

    // When the user uses a history back to go back to the page, the browser fills the
    // previous values into the form, but after the DOMContentLoaded. So we need to do
    // the initialization in a pageshow event.
    if (radioToggles.length > 0) {
        window.addEventListener('pageshow', event => {
            hideAllRadioToggleSelectors()
            displaySelectedRadioToggleSelector()
        })
    }

    function hideAllRadioToggleSelectors() {
        radioToggles.forEach(radio => {
            const toggleSelector = radio.dataset['radioToggleSelector'];
            if (!toggleSelector)
                return
            document.querySelectorAll(toggleSelector).forEach(elementToToggle => {
                setVisibilityAndInputsRequired(elementToToggle, false);
            })
        })
    }

    function displaySelectedRadioToggleSelector() {
        radioToggles.forEach(radio => {
            if (radio.checked) {
                const toggleSelector = radio.dataset['radioToggleSelector'];
                if (!toggleSelector)
                    return
                document.querySelectorAll(toggleSelector).forEach(elementToToggle => {
                    setVisibilityAndInputsRequired(elementToToggle, true);
                })
            }
        })
    }
})

document.addEventListener("DOMContentLoaded", event => {
    const checkboxToggles = document.querySelectorAll('input[type=checkbox][data-checkbox-toggle-selector]') as NodeListOf<HTMLInputElement>

    checkboxToggles.forEach(checkbox => {
        checkbox.addEventListener('change', (event) => {
            switchDisplay(checkbox)
        })
    })

    window.addEventListener('pageshow', event => {
        checkboxToggles.forEach(checkbox => {
            if (!!checkbox.attributes.getNamedItem("checked") !== checkbox.checked) {
                switchDisplay(checkbox)
            }
        })
    })

    function switchDisplay(checkbox: HTMLInputElement) {
        const toggleSelector = checkbox.dataset['checkboxToggleSelector'];
        if (!toggleSelector)
            return
        document.querySelectorAll(toggleSelector).forEach(elementToToggle => {
            const wereHidden = (elementToToggle as HTMLElement).style.display === 'none';
            setVisibilityAndInputsRequired(elementToToggle, wereHidden);
        })
    }
})

document.addEventListener('DOMContentLoaded', event => {
    const optionsToggles = document.querySelectorAll('option[data-option-toggle-selector]') as NodeListOf<HTMLElement>

    function findParentSelect(element: HTMLElement): HTMLInputElement {
        if (element.parentElement!.tagName === 'SELECT')
            return element.parentElement as HTMLInputElement
        return findParentSelect(element.parentElement!)
    }

    const selects = new Set(Array.from(optionsToggles).map(option => findParentSelect(option as HTMLElement)))
    selects.forEach(select => {
        select.addEventListener('change', (event) => {
            hideAllOptionToggleSelectors()
            displaySelectedOptionToggleSelector()
        })
    })
    // When the user uses a history back to go back to the page, the browser fills the
    // previous values into the form, but after the DOMContentLoaded. So we need to
    // do the initialization in a pageshow event.
    if (optionsToggles.length > 0) {
        window.addEventListener('pageshow', event => {
            hideAllOptionToggleSelectors()
            displaySelectedOptionToggleSelector()
        })
    }

    function hideAllOptionToggleSelectors() {
        optionsToggles.forEach(option => {
            document.querySelectorAll((option as HTMLInputElement).dataset['optionToggleSelector']!).forEach(elementToToggle => {
                setVisibilityAndInputsRequired(elementToToggle, false);
            })
        })
    }

    function displaySelectedOptionToggleSelector() {
        optionsToggles.forEach(option => {
            if ((option as HTMLOptionElement).selected) {
                document.querySelectorAll((option as HTMLOptionElement).dataset['optionToggleSelector']!).forEach(elementToToggle => {
                    setVisibilityAndInputsRequired(elementToToggle, true);
                })
            }
        })
    }
})


window.eraseInputValue = function () {
    Array.from(arguments).forEach(
        (selector) => {
            const inputs = document.querySelectorAll(selector)
            inputs.forEach(input => {
                input.value = ''
            })
        })
}

const setVisibilityAndInputsRequired = (elementToToggle: Element, visible: boolean) => {
    (elementToToggle as HTMLElement).style.display = visible ? '' : 'none';
    if (elementToToggle.hasAttribute('toggle-required')) {
        (elementToToggle as HTMLInputElement).required = visible;
    }
    (elementToToggle as HTMLElement).querySelectorAll("[toggle-required]").forEach(input => {
        (input as HTMLInputElement).required = visible;
    })
}
